export const environment = {
  apiUrl: 'https://api.int.arkansas.prolinkplus.prolinksolutions.com/api',
  applicationStageCount: 8,
  clientName: 'adfa',
  cognitoAuthenticationFlowtype: 'USER_SRP_AUTH',
  cognitoRegion: 'us-east-1',
  cognitoUserPoolId: 'us-east-1_6s8fmgvRD',
  cognitoUserPoolWebClientId: '18nk2epnseuemeq78nl0al90al',
  googleAnalyticsId: '',
  hotjarId: '',
  loadZenDesk: false,
  production: false,
  allowLanguageChanges: false,
  recaptchaSiteKey: '6Ld0yzUbAAAAAFKFVUxnh7BjmuSmcowTaeCrpC2o',
  setTimeout: 30,
  smartyStreetsKey: '',
  stateAbbreviation: 'AR',
  stateId: 4,
  timeOut: 1800,
  timestamp: '2/25/2025 9:31:24 PM',
  title: 'Arkansas HAF - ProLink+',
  uploadFileSize: 50000000,
  zenDeskUrl: '',
  showReasonableAccommodation: false,
  showLanguageAssistance: false,
  showDiscriminationComplaints: false,
  showLoginPageHeroBanner: false,
  supportedLanguages: [ { name: 'English', value:'en'}, { name: 'Español', value:'es'}]
};
